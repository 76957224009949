import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { TabsMbl } from '../tabsMbl';
import { About } from '../about';
import styles from './styles.module.css';
import GetPriorityPassModal from '../../modals/custom-modal';
import UnsubscribeModal from '../../modals/custom-modal';
import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { useSnackbar } from 'react-simple-snackbar';
import { snackBarOptions } from '../../../utils/snackbar-options';
import fetcher, { fetcherPost } from '../../../utils/fetch';
import { H4LightGrey, P2Grey4, P2White, PWhite } from '../../styled-typography';
import { useRouter } from 'next/router';
import { AppLoader } from '../../loader';
import { LoginModal } from '../../modals/login-modal';
import Confetti from '../../modals/confetti-modal';
import { getFirstName } from '../../../utils/get-first-name';
import { amplitudeUtil } from '../../../utils/amplitude/client';

export const ProfileInfo = ({ profileData, activeTab, setActiveTab }) => {
    const router = useRouter();
    const source = (router.query?.s as string) ?? '';
    const medium = (router.query?.m as string) ?? '';
    const action = (router.query?.a as string) ?? '';

    const { data: session, status } = useSession();
    const sessionLoading = status === 'loading';
    const [showLogin, setShowLogin] = useState(false);

    const [loader, setLoader] = useState(false);
    const [loaderText, setLoaderText] = useState('Loading');

    const [priorityPassModalActive, setPriorityPassModalActive] =
        useState(false);
    const [unsubscribeModalActive, setUnsubscribeModalActive] = useState(false);

    const [subscriptionEmail, setSubscriptionEmail] = useState(
        session?.contactEmail ?? ''
    );
    const [showConfetti, setShowConfetti] = useState(false);
    const [subscriptionStatusLoading, setSubscriptionStatusLoading] =
        useState(undefined);
    const [openSnackbar] = useSnackbar(snackBarOptions);
    const [subscribed, setSubscribed] = useState(undefined);
    const [priorityUser, setPriorityUser] = useState(undefined);
    const [loginCallbackParams, setLoginCallbackParams] = useState('');
    const creatorFirstName = getFirstName(profileData.name);

    const [accessNfts, setAccessNfts] = useState({ remaining: 0 });
    const [lastOrderUrl, setLastOrderUrl] = useState(undefined);

    const onSubscribe = () => {
        amplitudeUtil.log('Subscribe [Clicked]', {
            loggedIn: session === null || session === undefined ? false : true,
            subscribed: subscribed,
            subscribingTo: {
                id: profileData._id,
                username: profileData.username,
                creatorName: profileData.name,
            },
            user: session
                ? {
                      id: session.userId,
                      username: session.username,
                      name: session.name,
                      email: session.email,
                  }
                : null,
        });

        if (!sessionLoading && !session) {
            setLoginCallbackParams('?s=login&m=modal&a=subscribe');
            setShowLogin(true);
        } else {
            subscribeCreator();
        }
    };

    const subscribeCreator = () => {
        if (subscribed === undefined) {
            setLoaderText('Fetching subscription status');
            setLoader(true);
            return;
        } else if (subscribed === true) {
            setLoaderText('Loading');
            setLoader(false);
            openSnackbar('Already Subscribed!', 2000);
        } else {
            setLoaderText('Subscribing');
            setLoader(true);
            fetcherPost('/api/subscribe-creator', {
                creatorId: profileData._id,
                creatorName: profileData.name,
                subscriptionLink: profileData.subscriptionLink,
            })
                .then((response) => {
                    if (response.status) {
                        amplitudeUtil.log('Subscribe [Success]', {
                            loggedIn: true,
                            subscribed: true,
                            subscribingTo: {
                                id: profileData._id,
                                username: profileData.username,
                                creatorName: profileData.name,
                            },
                            user: {
                                id: session.userId,
                                username: session.username,
                                name: session.name,
                                email: session.email,
                            },
                        });

                        openSnackbar('Subscribed successfully!', 3000);
                        setSubscribed(true);
                    } else {
                        amplitudeUtil.log('Subscribe [Failed]', {
                            loggedIn: true,
                            subscribed: false,
                            subscribingTo: {
                                id: profileData._id,
                                username: profileData.username,
                                creatorName: profileData.name,
                            },
                            user: {
                                id: session.userId,
                                username: session.username,
                                name: session.name,
                                email: session.email,
                            },
                        });

                        console.log('Subscription failed', response);
                        openSnackbar('Subscription failed!', 3000);
                    }
                })
                .catch((error) => {
                    console.error('Creator Subscription failed', error);
                    amplitudeUtil.log('Subscribe [Failed]', {
                        loggedIn: true,
                        subscribed: false,
                        subscribingTo: {
                            id: profileData._id,
                            username: profileData.username,
                            creatorName: profileData.name,
                        },
                        user: {
                            id: session.userId,
                            username: session.username,
                            name: session.name,
                            email: session.email,
                        },
                    });
                })
                .finally(() => {
                    setLoader(false);
                    setLoaderText('Loading');
                });
        }
    };

    const unsubscribeCreator = () => {
        setUnsubscribeModalActive(false);
        setLoaderText('Unsubscribing');
        setLoader(true);
        fetcherPost('/api/unsubscribe-creator', {
            creatorId: profileData._id,
            subscriptionLink: profileData.subscriptionLink,
        })
            .then((response) => {
                if (response.status) {
                    amplitudeUtil.log('Unsubscribe [Success]', {
                        loggedIn: true,
                        unsubscribed: true,
                        subscribingTo: {
                            id: profileData._id,
                            username: profileData.username,
                            creatorName: profileData.name,
                        },
                        user: {
                            id: session.userId,
                            username: session.username,
                            name: session.name,
                            email: session.email,
                        },
                    });

                    openSnackbar('Unsubscribed successfully!', 3000);
                    setSubscribed(false);
                } else {
                    amplitudeUtil.log('Unsubscribe [Failed]', {
                        loggedIn: true,
                        unsubscribed: false,
                        subscribingTo: {
                            id: profileData._id,
                            username: profileData.username,
                            creatorName: profileData.name,
                        },
                        user: {
                            id: session.userId,
                            username: session.username,
                            name: session.name,
                            email: session.email,
                        },
                    });

                    console.log('Unsubscription failed', response);
                    openSnackbar('Unsubscription failed!', 3000);
                }
            })
            .catch((error) => {
                console.error('Creator Unsubscription failed', error);
                amplitudeUtil.log('Unsubscribe [Failed]', {
                    loggedIn: true,
                    unsubscribed: false,
                    subscribingTo: {
                        id: profileData._id,
                        username: profileData.username,
                        creatorName: profileData.name,
                    },
                    user: {
                        id: session.userId,
                        username: session.username,
                        name: session.name,
                        email: session.email,
                    },
                });
            })
            .finally(() => {
                setLoader(false);
                setLoaderText('Loading');
            });
    };

    const onBuyPriorityPass = () => {
        if (!sessionLoading && !session) {
            setLoginCallbackParams('?s=login&m=modal&a=priority');
            setShowLogin(true);
        } else {
            buyPriorityPass();
        }
    };

    const buyPriorityPass = () => {
        if (priorityUser === undefined) {
            setLoaderText('Fetching subscription status');
            setLoader(true);
            return;
        } else if (priorityUser === true) {
            setLoaderText('Loading');
            setLoader(false);
            openSnackbar('Already Priority User!', 2000);
        } else {
            setPriorityPassModalActive(false);
            setLoaderText('Redirecting to payment gateway');
            setLoader(true);
            if (lastOrderUrl) {
                router.push(lastOrderUrl);
            } else {
                fetcherPost('api/buy-priority-pass', {
                    creatorId: profileData._id,
                })
                    .then((data) => {
                        if (data.hosted_url) router.push(data.hosted_url);
                        else if (data.alreadySubscribed) {
                            // setLoader(false);
                            openSnackbar(
                                'You already have priority access!',
                                3000
                            );
                        } else if (data.alreadyReserved) {
                            // setLoader(false);
                            openSnackbar(
                                'You already have a pending reservation!',
                                3000
                            );
                            if (lastOrderUrl) router.push(lastOrderUrl);
                        } else if (!data.reserveAvailable) {
                            // setLoader(false);
                            openSnackbar(
                                'The queue is full. Please check back in 10 mins!',
                                3000
                            );
                        } else {
                            console.log('Coinbase Charge ', data);
                            // setLoader(false);
                            openSnackbar(
                                'Reservation failed, please try again!',
                                3000
                            );
                        }
                    })
                    .catch((error) => {
                        console.error('Error Buy Priority Pass', error);
                        // setLoader(false);
                        // setLoaderText('Loading..');
                        openSnackbar('Something went wrong!', 3000);
                    })
                    .finally(() => {
                        setLoader(false);
                        setLoaderText('Loading..');
                    });
            }
        }
    };
    useEffect(() => {
        if (
            session &&
            (priorityUser === undefined || subscribed === undefined)
        ) {
            fetcher(
                `/api/get-access-nfts?creatorUsername=${profileData.username}`
            )
                .then((response) => {
                    setAccessNfts(response.data);
                })
                .catch((error) => {
                    console.log('ERROR', error);
                });

            setSubscriptionStatusLoading(true);
            console.log('Fetching user subscription status');
            fetcher(`/api/check-priority-pass?creatorId=${profileData._id}`)
                .then((response) => {
                    if (response.status && response.data) {
                        const userPriorityPass = response.data;
                        setLastOrderUrl(userPriorityPass?.lastOrderUrl ?? '');
                        console.log(
                            'User subscription status',
                            userPriorityPass
                        );
                        if (userPriorityPass.subscriptionType == 'free') {
                            setSubscribed(true);
                            setPriorityUser(false);
                        } else if (
                            userPriorityPass.subscriptionType == 'priority'
                        ) {
                            setSubscribed(true);
                            setPriorityUser(true);
                        } else {
                            setSubscribed(false);
                            setPriorityUser(false);
                        }
                    } else {
                        console.debug(
                            'check-priority-pass apis failed',
                            response
                        );
                        setSubscribed(false);
                        setPriorityUser(false);
                    }
                })
                .catch((error) => {
                    console.error('check-priority-pass error', error);
                    setSubscribed(undefined);
                    setPriorityUser(undefined);
                })
                .finally(() => {
                    setSubscriptionStatusLoading(false);
                });
        }
        return () => {};
    }, [sessionLoading]);

    useEffect(() => {
        // auto redirecting to payment gateway on login success //
        if (
            !sessionLoading &&
            !subscriptionStatusLoading &&
            session &&
            source == 'login' &&
            medium == 'modal' &&
            action == 'priority'
        ) {
            router
                .replace(router.asPath, '/' + profileData.username)
                .finally(() => {
                    console.log('Invoking Buy Priority Pass');
                    buyPriorityPass();
                });
        }
        // ---------------------------------------------------- //

        // auto subscribe on login success //
        if (
            !sessionLoading &&
            !subscriptionStatusLoading &&
            session &&
            source == 'login' &&
            medium == 'modal' &&
            action == 'subscribe'
        ) {
            router
                .replace(router.asPath, '/' + profileData.username)
                .finally(() => {
                    console.log('Invoking Creator Subscribe');
                    subscribeCreator();
                });
        }
        // ---------------------------------------------------- //

        // redirected by coinbase on success //
        if (
            !sessionLoading &&
            !subscriptionStatusLoading &&
            session &&
            source == 'coinbase' &&
            medium == 'charge' &&
            action == 'redirect'
        ) {
            router
                .replace(router.asPath, '/' + profileData.username)
                .finally(() => {
                    setPriorityUser(true);
                    setShowConfetti(true);
                });
        }
        // ---------------------------------------------------- //

        // redirected by coinbase on cancel //
        if (
            !sessionLoading &&
            !subscriptionStatusLoading &&
            session &&
            source == 'coinbase' &&
            medium == 'charge' &&
            action == 'cancel'
        ) {
            router
                .replace(router.asPath, '/' + profileData.username)
                .finally(() => {
                    openSnackbar('Payment Cancelled!');
                });
        }
        // ---------------------------------------------------- //

        setSubscriptionEmail(session?.contactEmail ?? '');
        return () => {
            setSubscriptionEmail('');
        };
    }, [sessionLoading, subscriptionStatusLoading]);

    return (
        // TODO (test): Instead of getting the data from the NextAuth session, get the profile data from the state so it can be updated on the client side.
        <div className={styles.profileInfoContainer}>
            <div className="row">
                <div className="col-sm-12 d-flex justify-content-center justify-content-lg-start m-0">
                    <h1
                        style={{ wordBreak: 'break-word' }}
                        className={`text-center text-lg-start color-black font-60 mt-4`}
                    >
                        {profileData?.name ?? 'Creator'}
                    </h1>
                </div>
            </div>
            {priorityUser && session?.username != profileData.username && (
                <div
                    className={`${styles.subscriptionContainer} d-flex flex-column`}
                >
                    <button
                        disabled={priorityUser}
                        className={styles.proirityPassSubscribed}
                    >
                        Subscribed
                    </button>
                </div>
            )}
            {!priorityUser &&
                !sessionLoading &&
                !subscriptionStatusLoading &&
                profileData.isCreator &&
                profileData.subscriptionLink &&
                session?.username != profileData.username && (
                    <div
                        className={`${styles.subscriptionContainer} d-flex flex-column`}
                    >
                        {profileData?.accessNftsEnabled && (
                            <P2Grey4 className="d-flex">
                                Mint "Access NFT" to get access to{' '}
                                {creatorFirstName}'s upcoming drop (or subscribe
                                for general updates).
                            </P2Grey4>
                        )}
                        {!profileData?.accessNftsEnabled && (
                            <P2Grey4 className="d-flex justify-content-center">
                                Subscribe now to receive updates on{' '}
                                {creatorFirstName}'s upcoming experiences
                            </P2Grey4>
                        )}
                        <div className={styles.subscriptionButtonContainer}>
                            {profileData?.accessNftsEnabled && (
                                <button
                                    id="mint-access-nft"
                                    disabled={priorityUser}
                                    className={styles.proirityPass}
                                    onClick={() =>
                                        setPriorityPassModalActive(true)
                                    }
                                >
                                    Mint Access NFT
                                </button>
                            )}

                            <button
                                id="subscribe-creator"
                                className={`${
                                    subscribed
                                        ? styles.subscribed
                                        : styles.subscribe
                                }`}
                                onClick={
                                    subscribed
                                        ? // ? unsubscribeCreator
                                          () => setUnsubscribeModalActive(true)
                                        : onSubscribe
                                }
                            >
                                {subscribed ? 'Unsubscribe' : 'Subscribe'}
                            </button>
                        </div>
                    </div>
                )}
            {session?.username == profileData.username && (
                <div className={styles.editButtonContainer}>
                    <Link href="/settings">
                        <button className={styles.editButton}>Edit</button>
                    </Link>
                </div>
            )}
            <div className={`d-none d-lg-block`}>
                <About profileData={profileData} />
            </div>

            <div className="mx-md-4 my-4">
                <TabsMbl
                    profileData={profileData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            <UnsubscribeModal
                isModalOpen={unsubscribeModalActive}
                handleModalClose={() => setUnsubscribeModalActive(false)}
            >
                <div className="d-flex justify-content-center align-items-center">
                    <div className={styles.proirityPassModal}>
                        <div className={styles.description}>
                            <PWhite className="mb-2">
                                Are you sure you want to unsubscribe?
                            </PWhite>
                        </div>
                        <div className="d-flex flex-column align-content-center">
                            <button
                                onClick={unsubscribeCreator}
                                className={styles.unsubModalButton}
                            >
                                Unsubscribe
                            </button>
                            <button
                                onClick={() => setUnsubscribeModalActive(false)}
                                className={styles.cancelButton}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </UnsubscribeModal>
            <GetPriorityPassModal
                isModalOpen={priorityPassModalActive}
                handleModalClose={() => setPriorityPassModalActive(false)}
            >
                <div className="d-flex justify-content-center align-items-center">
                    <div className={styles.proirityPassModal}>
                        <div className="d-flex">
                            <div className="">
                                <H4LightGrey>
                                    {/* Mint <br /> */}
                                    Access NFT
                                </H4LightGrey>
                            </div>
                            <div className="mx-1">
                                <Image
                                    priority
                                    src="/assets/icon/ticket.svg"
                                    alt="logo"
                                    objectFit="cover"
                                    width="153"
                                    height="146"
                                />
                            </div>
                        </div>
                        <div className={styles.description}>
                            <PWhite className="mb-2">
                                {creatorFirstName}'s dropping super exclusive
                                NFTs soon. These NFTs give holders unprecedented
                                experiences with {creatorFirstName}!
                            </PWhite>

                            <PWhite className="mb-2">
                                To get access to this upcoming drop, mint{' '}
                                {creatorFirstName}'s "Access NFT" now. Other
                                perks include:
                            </PWhite>
                            <ul className="mb-2">
                                <li>
                                    <P2White className="mb-1">
                                        Potential airdrops of super rare, 1-of-1
                                        NFTs by {creatorFirstName} in 2022.
                                    </P2White>
                                </li>
                                <li>
                                    <P2White className="mb-1">
                                        Trade the Access NFT on our upcoming
                                        secondary market.
                                    </P2White>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-column align-content-center">
                            <button
                                id="buy-access-nft"
                                onClick={onBuyPriorityPass}
                                className={styles.buyButton}
                                disabled={accessNfts?.remaining < 1}
                            >
                                {accessNfts?.remaining < 1
                                    ? 'Sorry, everything is sold out!'
                                    : 'Mint Access NFT for $20'}
                            </button>
                            <button
                                id="close-access-nft"
                                onClick={() =>
                                    setPriorityPassModalActive(false)
                                }
                                className={styles.cancelButton}
                            >
                                {accessNfts?.remaining < 1
                                    ? 'Alright, take me back'
                                    : 'Not for me, thank you'}
                            </button>
                        </div>
                    </div>
                </div>
            </GetPriorityPassModal>
            <Confetti
                show={showConfetti}
                description={`Payment successful! We're minting your "Access NFT" for ${creatorFirstName}.`}
                caption={`We'll email you once it's minted.`}
                actionText={`Close this`}
                actionHandle={() => {
                    setShowConfetti(false);
                }}
            />
            <LoginModal
                show={showLogin}
                setShow={setShowLogin}
                callbackPath={`/${profileData.username}${loginCallbackParams}`}
                creatorData={profileData}
            />
            {loader && <AppLoader text={loaderText} />}
        </div>
    );
};
