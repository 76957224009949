import { LOGO_IMAGE_PATH } from '../../../utils/constants';
import activityBarStyles from './activityBar.module.css';

export const ActivityBar = () => {
    return (
        <div className="w-100">
            <div
                className={`p-3 br-24 d-flex justify-content-between mb-4 ${activityBarStyles.mainDiv}`}
            >
                <div className="d-flex justify-content-center align-items-center">
                    <div className="px-2">
                        <img src={LOGO_IMAGE_PATH} width="30" />
                    </div>
                    <div className="px-2">
                        <div>
                            <p className="m-0 mb-2 font-12thin color-black">
                                dd/mm/yy
                            </p>
                        </div>
                        <div>
                            <h6 className="m-0 color-black font-25">
                                Purchased NFT’s name
                            </h6>
                        </div>
                    </div>
                </div>
                <div className="px-2">
                    <div>
                        <h6 className="m-0 color-black mb-2 font-25">
                            US $ 258.15
                        </h6>
                    </div>
                    <div>
                        <p className="m-0 color-black font-16">BTC: 0.05</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
