import { useState } from 'react';
import { useProfileOffers } from '../../../hooks/useProfileOffers';
import getFilteredAndSortedRelatedNfts from '../../../utils/get-related-nfts';
import { AppLoader } from '../../loader';
import { NFTCard } from '../../nft-card';
import { ProfileNFTFilter } from '../filters';
import styles from './styles.module.css';

export const Offers = ({ username }) => {
    // @todo handle pagination
    const { offers, isLoading: offersLoading } = useProfileOffers(username, 50);
    const [active, setActive] = useState('onsale');
    // const onsale =
    //     offers?.filter(
    //         (x: any) =>
    //             x.status == 'coming-soon' ||
    //             x.status == 'upcoming' ||
    //             x.status == 'onsale' ||
    //     ) ?? [];

    // const sold = offers?.filter((x: any) => x.status == 'sold') ?? [];
    console.log('check', offers);

    return (
        <>
            {offersLoading && <AppLoader></AppLoader>}
            {!offersLoading && (
                <div className={`w-100 ${styles.collectionWrapper}`}>
                    {/* <ProfileNFTFilter
                        active={active}
                        setActive={setActive}
                        onSaleCount={onSaleCount}
                        soldCount={soldCount}
                    /> */}
                    {active === 'onsale' && (
                        <>
                            {offers.length > 0 ? (
                                <div className="d-flex flex-wrap m-0 p-0">
                                    {offers
                                        .filter((el) => !el.airdrop)
                                        .map((el, index) => (
                                            <div
                                                className={`${styles.card} my-1 d-flex`}
                                                key={index}
                                            >
                                                <NFTCard
                                                    gradientBorder={true}
                                                    mediaUrl={el.mediaUrl}
                                                    _id={el._id}
                                                    creator={el.creator}
                                                    title={el.title}
                                                    description={
                                                        el.shortDescription ??
                                                        el.description
                                                    }
                                                    category={el.category.title}
                                                    auctionStartTime={
                                                        el.auctionStartTime
                                                    }
                                                    narrowCardContainer={true}
                                                />
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                <div className=" d-flex justify-content-center">
                                    <div className="my-5 text-center">
                                        <img
                                            src="/images/nothingonOffer.svg"
                                            alt={'empty'}
                                            className=""
                                        />
                                        <p className="font-20 mt-2 text-greyy">
                                            No offers live yet. But click
                                            subscribe to know when they arrive.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {/* {active === 'sold' && (
                        <>
                            {sold.length > 0 && (
                                <div className="d-flex flex-wrap m-0 p-0">
                                    {sold.map((el, index) => (
                                        <div
                                            className={`${styles.card} my-1 d-flex`}
                                            key={index}
                                        >
                                            <NFTCard
                                                gradientBorder={true}
                                                mediaUrl={el.mediaUrl}
                                                _id={el._id}
                                                creator={el.creator}
                                                title={el.title}
                                                description={
                                                    el.shortDescription ??
                                                    el.description
                                                }
                                                category={el.category.title}
                                                auctionStartTime={
                                                    el.auctionStartTime
                                                }
                                                narrowCardContainer={true}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            {sold.length <= 0 && onsale.length > 0 && (
                                <div className="d-flex justify-content-center">
                                    <div className="my-5 text-center">
                                        <img
                                            src="/images/sold-0.svg"
                                            alt={'empty'}
                                            className=""
                                        />
                                        <p className="font-20 mt-2 text-greyy">
                                            Let’s check what’s on sale.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {sold.length <= 0 && onsale.length <= 0 && (
                                <div className=" d-flex justify-content-center">
                                    <div className="my-5 text-center">
                                        <img
                                            src="/images/nothingonOffer.svg"
                                            alt={'empty'}
                                            className=""
                                        />
                                        <p className="font-20 mt-2 text-greyy">
                                            There is nothing here yet.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </>
                    )} */}
                </div>
            )}
        </>
    );
};
