import { minutesToMilliseconds } from 'date-fns';
import useSWR from 'swr';
import { FE_GET_USER_PROFILE_COLLECTION } from '../utils/api-urls-fe';

function useProfileCollection(username) {
    const { data, error } = useSWR(
        !username ? null : FE_GET_USER_PROFILE_COLLECTION(username),
        {
            refreshInterval: minutesToMilliseconds(1),
        }
    );

    return {
        collection: data?.data,
        isLoading: !error && !data,
        isError: error,
    };
}

export { useProfileCollection };
