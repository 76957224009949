import React from 'react';

export const TabsMbl = ({ profileData, activeTab, setActiveTab }) => {
    const { isCreator } = profileData;
    return (
        <>
            {isCreator && (
                <div className="mb-4 ">
                    <hr className="d-block color-light-gray d-lg-none" />
                    <div className="row px-2 px-lg-5 d-flex d-lg-none justify-content-around align-items-center text-center">
                        <div className="px-0 text-center d-flex justify-content-around">
                            <a
                                href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT_BANK_TRANSFER}`}
                                target={'_blank'}
                                rel="noopener noreferrer"
                            >
                                <p className="font-18-700 color-black cursor-pointer">
                                    For help and questions please WhatsApp{' '}
                                    <span
                                        style={{
                                            color: 'rgb(161, 44, 235)',
                                        }}
                                    >
                                        {
                                            process.env
                                                .NEXT_PUBLIC_WHATSAPP_CONTACT_BANK_TRANSFER
                                        }
                                    </span>
                                    . We reply instantly!
                                </p>
                            </a>
                        </div>
                        {/* {profileData?.isCreator && (
                    <div className="px-0 text-center d-flex justify-content-around">
                        <h6
                            onClick={() => setActiveTab('collection')}
                            className={`font-18-700  ${
                                activeTab === 'collection'
                                    ? 'color-purple'
                                    : 'color-black'
                            } cursor-pointer`}
                        >
                            Upcoming NFTs
                        </h6>
                    </div>
                )}
                {!profileData?.isCreator && (
                    <div className="px-0 d-flex text-center justify-content-around">
                        <h6
                            onClick={() => setActiveTab('owned')}
                            className={`font-18-700  ${
                                activeTab === 'owned'
                                    ? 'color-purple'
                                    : 'color-black'
                            } cursor-pointer`}
                        >
                            Owned
                        </h6>
                    </div>
                )} */}
                        {/* <div className="col-4 px-0 d-flex text-center justify-content-around">
                    <h6
                        onClick={() => setActiveTab('activity')}
                        className={`font-18-700  ${
                            activeTab === 'activity'
                                ? 'color-purple'
                                : 'color-black'
                        } cursor-pointer`}
                    >
                        Activity
                    </h6>
                </div> */}
                    </div>
                    <hr className="d-block m-0  color-light-gray mt-2 d-lg-none" />
                </div>
            )}
        </>
    );
};
