import React from 'react';
import styles from './styles.module.css';
import { NFTCard } from '../../nft-card';
import Link from 'next/link';
import { useProfileCollection } from '../../../hooks/useProfileCollection';
import { AppLoader } from '../../loader';

export const Collect = ({ username, isMineProfile }) => {
    const { collection, isLoading: collectionLoading } =
        useProfileCollection(username);
    return (
        <>
            {collectionLoading && <AppLoader></AppLoader>}
            {!collectionLoading && (
                <div className={styles.collectionWrapper}>
                    {collection.length > 0 ? (
                        <div className="d-flex flex-wrap">
                            {collection?.map((el, index) => (
                                <div
                                    className={`${styles.card} my-1 d-flex`}
                                    key={index}
                                >
                                    <NFTCard
                                        mediaUrl={el.mediaUrl}
                                        _id={el._id}
                                        creator={el.creator}
                                        title={el.title}
                                        description={
                                            el.shortDescription ??
                                            el.description
                                        }
                                        category={el.category.title}
                                        auctionStartTime={el.auctionStartTime}
                                        showCreatorInfo={true}
                                        narrowCardContainer={true}
                                        gradientBorder={true}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <>
                                    <img
                                        src="/images/nocollect.svg"
                                        alt={'empty'}
                                        className=""
                                    />
                                    {isMineProfile ? (
                                        <div>
                                            <Link href="/">
                                                <button className="mt-2 py-2 px-3 font-16-bold-500 br-24 border-pink color-pink bg-transparent">
                                                    Browse NFTs
                                                </button>
                                            </Link>
                                        </div>
                                    ) : (
                                        <p className="font-20 mt-2 text-greyy">
                                            No NFT collected yet.
                                        </p>
                                    )}
                                </>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
