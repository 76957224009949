import React, { useState } from 'react';
import styles from './profilePicAndTabs.module.css';
import ProfileInfoModal from '../../modals/custom-modal';
import { StickyProfileNameAndImg } from '../stickyCelebrityInfo/stickyNameAndImage';
import prependSocialBaseUrl from '../../../utils/social-base-urls';
import { SocialIcons } from '../../icons/socialIcons';

export const ProfilePicAndTabs = ({ setActiveTab, activeTab, profileData }) => {
    const { socialLinks, isCreator, name, displayImage, about } = profileData;

    const [isModalOpen, setModalOpen] = useState(false);

    const handleModalOpen = (e) => {
        setModalOpen(true);
        e.stopPropagation();
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <div className="d-flex">
            <div className="position-static m-0 p-0 col-12 col-lg-4 d-flex flex-row justify-content-center justify-content-lg-start">
                <div>
                    <div
                        onClick={handleModalOpen}
                        className="d-flex position-relative"
                    >
                        <img
                            className={styles.profilePicture}
                            src={
                                displayImage?.length
                                    ? displayImage
                                    : '/images/basicprofile.svg'
                            }
                            alt="profile-picture"
                        />
                        {isCreator && (
                            <img
                                className={styles.celPrflVerifyIcon}
                                src="/assets/icon/verify.svg"
                                alt="verify-icon"
                            />
                        )}
                    </div>
                </div>
            </div>

            {isCreator && (
                <div
                    className="col-lg-8 d-none d-lg-flex bg-white rounded-pill shadow border cursor-pointer mt-5 mb-4"
                    style={{
                        height: '64px',
                        maxWidth: '816px',
                    }}
                >
                    <a
                        href={`https://wa.me/${process.env.NEXT_PUBLIC_WHATSAPP_CONTACT_BANK_TRANSFER}`}
                        target={'_blank'}
                        rel="noopener noreferrer"
                        className="w-100 text-center text-dark h-100 d-flex align-items-center justify-content-center"
                    >
                        <p className="m-0">
                            For help and questions please WhatsApp{' '}
                                    <span
                                        style={{
                                            color: 'rgb(161, 44, 235)',
                                        }}
                                    >
                                        {
                                            process.env
                                                .NEXT_PUBLIC_WHATSAPP_CONTACT_BANK_TRANSFER
                                        }
                                    </span>
                                    . We reply instantly!
                        </p>
                    </a>
                </div>
            )}

            {/* <div
                className={`d-none d-lg-flex px-4 col-lg-8 justify-content-around align-items-center ${styles.celPrflHeaderTabs}`}
            >
                {isCreator && (
                    <button
                        onClick={() => setActiveTab('collection')}
                        style={{ zIndex: 1 }}
                        className={
                            'd-flex justify-content-start border-0 bg-transparent  py-2 px-1'
                        }
                    >
                        <h6
                            className={`font-24 m-0 ${
                                activeTab === 'collection'
                                    ? 'color-purple'
                                    : 'color-black'
                            }`}
                        >
                            Upcoming NFTs
                        </h6>
                    </button>
                )}
                {!isCreator && (
                    <button
                        style={{ zIndex: 1 }}
                        onClick={() => setActiveTab('owned')}
                        className={
                            'd-flex justify-content-start border-0 bg-transparent py-2 px-1'
                        }
                    >
                        <h6
                            className={`font-24 m-0 ${
                                activeTab === 'owned'
                                    ? 'color-purple'
                                    : 'color-black'
                            }`}
                        >
                            Owned
                        </h6>
                    </button>
                )}
                <button
                    style={{ zIndex: 1 }}
                    onClick={() => setActiveTab('activity')}
                    className={
                        'col-3 d-flex justify-content-start border-0 bg-transparent py-2 px-1'
                    }
                >
                    <h6
                        className={`font-24  m-0 ${
                            activeTab === 'activity'
                                ? 'color-purple'
                                : 'color-black'
                        }`}
                    >
                        Activity
                    </h6>
                </button>
            </div> */}

            <ProfileInfoModal
                isModalOpen={isModalOpen}
                handleModalClose={handleModalClose}
            >
                <div
                    className="p-4"
                    style={{ background: 'white', width: 'inherit' }}
                >
                    <div className="row px-2 px-lg-4">
                        <div className="d-flex justify-content-end">
                            <img
                                src="/assets/icon/close.svg"
                                alt={'close'}
                                onClick={handleModalClose}
                                className="text-center cursor-pointer"
                            />
                        </div>

                        <div
                            className={`mt-4 p-1 ${styles.profileImageNameDiv}`}
                        >
                            <StickyProfileNameAndImg
                                name={name}
                                displayImage={displayImage}
                            />
                        </div>
                        <div>
                            <div className="w-100 col-4 mt-5 d-flex d-md-none flex-column justify-content-center align-items-start">
                                <h4 className="color-black font-32 pb-0">
                                    {profileData.onSaleCount}
                                </h4>
                                <h6 className="color-black font-18">
                                    NFTs on sale
                                </h6>
                            </div>
                            <div className="w-100 col-4 mt-5 d-flex d-md-none flex-column justify-content-center align-items-start">
                                <h4 className="color-black font-32 pb-0">
                                    {profileData.soldCount}
                                </h4>
                                <h6 className="color-black font-18">
                                    NFTs Sold
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mg-top-40 px-2 px-lg-4">
                        <div className="col-12 d-flex flex-column justify-content-center align-items-start m-0">
                            <div className="color-black font-24 w-100  border-bottom-black">
                                About
                            </div>
                            <p className="color-black text-left font-20 mt-3">
                                {about}
                            </p>
                        </div>
                    </div>
                    <div className="row mt-3 px-2 px-lg-4">
                        {socialLinks?.map((el, index) => (
                            <div key={index} className="col-3 col-lg-2 mb-3">
                                <SocialIcons
                                    href={prependSocialBaseUrl(
                                        el.platform,
                                        el.url
                                    )}
                                    height={'40'}
                                    width={'auto'}
                                    color={'black'}
                                    icon={el.platform}
                                ></SocialIcons>
                            </div>
                        ))}
                    </div>
                </div>
            </ProfileInfoModal>
        </div>
    );
};
