import { NextPage } from 'next';
import Layout from '../../components/layout';
import CelebrityProfile from '../../components/viewCelebrityProfile';
import { ProfileAPI } from '../../services/profiles';
import { IProfile } from '../../interfaces';
import { TrendingAPI } from '../../services/trending';
import { useRouter } from 'next/router';
import { AppLoader } from '../../components/loader';
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useSession } from 'next-auth/react';
import { amplitudeUtil } from '../../utils/amplitude/client';

const Profile: NextPage<any> = ({ profile }: { profile: IProfile }) => {
    const router = useRouter();
    const { data: session } = useSession();

    useEffect(() => {
        if (profile && profile.isCreator) {
            amplitudeUtil.log('Celebrity Profile [Visited]', {
                user: session
                    ? { name: session.name, email: session.email }
                    : null,
                celebrity: {
                    id: profile._id,
                    username: profile.username,
                    name: profile.name,
                },
            });
        }
    }, []);

    const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL;
    const FB_APP_ID = process.env.NEXT_PUBLIC_FB_APP_ID;

    let title = `${profile?.name} - NFT `;
    if (profile?.isCreator) title += 'Offers and ';
    title += 'Collections';

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta
                    property="og:url"
                    content={`${APP_BASE_URL}/${profile?.username}`}
                />
                <meta property="og:type" content="website" />
                <meta property="fb:app_id" content={FB_APP_ID} />
                <meta property="og:title" content={title} />
                <meta name="twitter:card" content="summary" />
                <meta property="og:description" content={profile?.about} />
                <meta
                    property="og:image"
                    itemProp="image"
                    content={profile?.coverImage}
                />
            </Head>
            <Layout>
                {/* If the page is not yet generated, this will be displayed 
                initially until getStaticProps() finishes running */}
                {router.isFallback && <AppLoader></AppLoader>}
                {!router.isFallback && (
                    <CelebrityProfile profileData={profile} />
                )}
            </Layout>
        </>
    );
};
export default Profile;

export const getStaticProps = async ({ params }: any) => {
    const profile: any = await ProfileAPI.getProfileDetail(params.username);

    if (!profile) {
        if (process.env.DEBUG == 'true')
            console.log('/[username] - Profile not found for params ', params);
        return {
            notFound: true,
        };
    }
    return {
        props: {
            profile,
        },
        // we will attempt to re-generate the page:
        // - when a request comes in
        revalidate: 60 * 60, // 1 hour
    };
};

export async function getStaticPaths() {
    if (process.env.DEBUG == 'true')
        console.debug('getStaticPaths - [username]');

    const influencers: any = await TrendingAPI.creators(100);
    if (process.env.DEBUG == 'true')
        console.debug(
            'getStaticPaths - [username] - trendingCreators',
            influencers
        );

    if (!influencers) return { paths: [], fallback: true };

    // Get the paths we want to pre-render based on trending influencers
    const paths = influencers.map((user: any) => ({
        params: { username: user.username },
    }));

    // We'll pre-render only these paths at build time.
    // { fallback: blocking } will server-render pages
    // on-demand if the path doesn't exist.
    return { paths: paths, fallback: true };
}
