import React, { useEffect, useState } from 'react';
import styles from './profileHead.module.css';
import useImageUpload from '../../../hooks/useImageUpload';
import { AppLoader } from '../../loader';
import { useSession } from 'next-auth/react';
import { fetcherPost } from '../../../utils/fetch';

export const ProfileHead = ({ profileUsername, userCover }) => {
    const { data: session } = useSession();

    const {
        uploading,
        preview,
        success,
        resultUrl,
        onChangeFile: upload,
    } = useImageUpload({
        maxFileSize: 10,
        maxWidthOrHeight: 1920,
        compressImage: true,
    });

    // TODO: Need to move this to redux store
    const [coverImage, setCoverImage] = useState(userCover ?? '');

    const [updatingData, setUpdatingData] = useState(false);

    const clearCover = () => {
        setCoverImage('');
        updateData('')
            .then(() => {
                // console.log('updatingData', true);
            })
            .catch((error) => {
                console.error('updatingData', false, error);
            })
            .finally(() => setUpdatingData(false));
    };

    const updateData = async (coverImage) => {
        const result = await fetcherPost('api/update-cover', {
            coverImage,
        });
        // console.log('upload-cover', result);
        return result;
    };

    useEffect(() => {
        console.log('useEffect updateData');
        if (success) {
            setUpdatingData(true);
            updateData(resultUrl)
                .then(() => {
                    // console.log('updatingData', true);
                })
                .catch((error) => {
                    console.error('updatingData', false, error);
                })
                .finally(() => setUpdatingData(false));
        }
    }, [success]);

    useEffect(() => {
        // console.log('useEffect updateCover');
        setCoverImage(resultUrl ?? preview ?? userCover);
    }, [resultUrl, preview, userCover]);

    return (
        <div className="row m-0 p-0">
            {uploading && <AppLoader text="Uploading File"></AppLoader>}
            {updatingData && <AppLoader text="Updating Data"></AppLoader>}

            <div className={`${styles.theHeader} col-12`}>
                <div>
                    {coverImage ? (
                        <img
                            data-section="cover"
                            className={`${styles.celPrflHeader} object-fit`}
                            src={coverImage}
                        />
                    ) : (
                        <div
                            data-section="default"
                            className={`${styles.celPrflHeaderBackground} object-fit`}
                        />
                    )}
                </div>
                {session?.username == profileUsername && (
                    <div className="d-flex mt-2 mt-sm-4 justify-content-end align-items-end flex-column flex-sm-row ">
                        {coverImage && (
                            <div onClick={clearCover}>
                                <label className={styles.deleteContainer}>
                                    <img
                                        src="/assets/icon/trash.png"
                                        width={18}
                                        height={18}
                                    />
                                </label>
                            </div>
                        )}
                        <div className="m-1"></div>
                        <div className="">
                            <label className={styles.uploadContainer}>
                                <img
                                    src="/assets/icon/edit.png"
                                    width={18}
                                    height={18}
                                />
                                <input
                                    className=""
                                    onChange={(e) => {
                                        upload(e.target.files[0]);
                                    }}
                                    type="file"
                                    accept="image/png,image/jpg,image/jpeg"
                                />
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
