import React from 'react';
import { IProfile } from '../../../interfaces';
import prependSocialBaseUrl from '../../../utils/social-base-urls';
import { SocialIcons } from '../../icons/socialIcons';

export const About = ({ profileData }) => {
    const { socialLinks, about } = profileData as IProfile;

    return (
        <>
            <div className="row mg-top-40">
                <div className="col-12 d-flex flex-column justify-content-center align-items-start m-0">
                    <div className="color-black font-24 w-100 pb-2 border-bottom-black">
                        About
                    </div>
                    <p className="color-black text-left font-20 mt-2">
                        {about}
                    </p>
                </div>
            </div>
            <div className="row mt-3">
                {socialLinks?.map((el, index) => {
                    return (
                        <div key={index} className="col-3 col-lg-2 mb-3">
                            <SocialIcons
                                href={prependSocialBaseUrl(el.platform, el.url)}
                                height={'40'}
                                width={'auto'}
                                color={'black'}
                                icon={el.platform}
                            ></SocialIcons>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
