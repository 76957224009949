export default function prependSocialBaseUrl(platform: string, id: string) {
    // return if the id already a url
    if (['https', 'http', 'www'].some((value) => id.startsWith(value)))
        return id;

    switch (platform) {
        case 'twitter':
            return 'https://twitter.com/' + id;
        case 'facebook':
            return 'https://facebook.com/' + id;
        case 'instagram':
            return 'https://instagram.com/' + id;
        case 'tiktok':
            return 'https://tiktok.com/@' + id;
        case 'telegram':
            return 'https://t.me/' + id;
        case 'twitch':
            return 'https://twitch.tv/' + id;
        case 'discord':
            return 'https://discord.gg/' + id;
        case 'youtube':
            return '' + id;
        default:
            return id;
    }
}
