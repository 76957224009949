import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { H5LightGrey, PMediumLightYellow } from '../../styled-typography';
import { Modal, ModalContent, ModalLayout } from '../custom-modal/index.styled';
import styles from './styles.module.css';

const Confetti = ({
    show,
    description = 'Congratulations!',
    caption = 'Your purchase was successful',
    actionText = 'Close',
    actionHandle = () => {},
    callToActionText = null,
    callToActionHandler = () => {},
}) => {
    const modalRef = useRef();
    return ReactDOM.createPortal(
        <ConfettiModalLayout active={show}>
            <ConfettiModal ref={modalRef}>
                <ConfettiModalContent>
                    <div className={styles.confetti}>
                        <H5LightGrey className={styles.description}>
                            {description}
                        </H5LightGrey>
                        <PMediumLightYellow className={styles.caption}>
                            {caption}
                        </PMediumLightYellow>

                        {callToActionText && (
                            <button
                                onClick={() => {
                                    callToActionHandler();
                                }}
                                className={styles.cta}
                            >
                                {callToActionText}
                            </button>
                        )}
                        <button
                            onClick={actionHandle}
                            className={styles.action}
                        >
                            {actionText}
                        </button>
                    </div>
                </ConfettiModalContent>
            </ConfettiModal>
        </ConfettiModalLayout>,
        document.getElementById('modal-root')
    );
};
const ConfettiModalLayout = styled(ModalLayout)``;

const ConfettiModal = styled(Modal)``;

const ConfettiModalContent = styled(ModalContent)`
    margin: 0px;
    padding: 0px;
`;

export default Confetti;
