import { ActivityBar } from '../activityBar';

export const Activity = ({ activity }) => {
    return (
        <div className="row m-0  w-100">
            {/* {card.cardsData.length > 0 ? <> */}

            <>
                {activity?.length > 0 ? (
                    <div className="w-100 col-12">
                        {activity?.map((el) => (
                            <ActivityBar />
                        ))}
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <img
                                src="/images/nothingonOffer.svg"
                                alt={'empty'}
                                className=""
                            />
                            <p className="font-20 mt-4 m-0 text-greyy">
                                Zzzzzzz.....
                            </p>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};
