import { useEffect, useState } from 'react';
import { Activity } from './activity';
import { Collect } from './profileNftCards/collection';
import { ProfileHead } from './head';
import { ProfileInfo } from './profileInfo';
import { Offers } from './profileNftCards/offers';
import { ProfilePicAndTabs } from './profilePicAndTabs';
import { StickyProfile } from './stickyCelebrityInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';

export default function ViewCelebrityProfile({ profileData }) {
    const [activeTab, setActiveTab] = useState(
        profileData?.isCreator ? 'collection' : 'owned'
    );

    const reduxSession = useSelector((state: RootState) => state.session);

    const [isMineProfile, setIsMineProfile] = useState(
        reduxSession?.username == profileData?.username ? true : false
    );

    const [profile, setProfile] = useState(profileData);
    useEffect(() => {
        setActiveTab(profileData?.isCreator ? 'collection' : 'owned');
        setIsMineProfile(
            reduxSession?.username == profileData?.username ? true : false
        );
        setProfile({
            _id: isMineProfile ? reduxSession?._id : profileData?._id,
            username: isMineProfile
                ? reduxSession?.username
                : profileData?.username,
            isCreator: isMineProfile
                ? reduxSession?.isCreator
                : profileData?.isCreator,
            displayImage: isMineProfile
                ? reduxSession?.displayImage
                : profileData?.displayImage,
            coverImage: profileData?.coverImage,
            socialLinks: isMineProfile
                ? reduxSession?.socialLinks
                : profileData?.socialLinks,
            name: isMineProfile ? reduxSession?.name : profileData?.name,
            about: isMineProfile ? reduxSession?.about : profileData?.about,
            tagline: isMineProfile
                ? reduxSession?.tagline
                : profileData?.tagline,
            soldCount: isMineProfile
                ? reduxSession?.soldCount
                : profileData?.soldCount,
            onSaleCount: isMineProfile
                ? reduxSession?.onSaleCount
                : profileData?.onSaleCount,
            subscriptionLink: isMineProfile
                ? reduxSession?.subscriptionLink
                : profileData?.subscriptionLink,
            launchStatus: isMineProfile
                ? reduxSession?.launchStatus
                : profileData?.launchStatus,
            accessNftsEnabled: isMineProfile
                ? reduxSession?.accessNftsEnabled
                : profileData?.accessNftsEnabled,
        });
    }, [isMineProfile, profileData, reduxSession]);

    return (
        <div
            style={{
                minHeight: '80vh',
            }}
        >
            <ProfileHead
                profileUsername={profile?.username}
                userCover={profile?.coverImage}
            />
            <StickyProfile profileData={profile} />
            <div className="container">
                <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0">
                        <ProfilePicAndTabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            profileData={profile}
                        />
                    </div>
                    <div className="d-flex justify-content-center m-0 justify-content-lg-start p-0 row col-12">
                        <div className="col-md-12 col-lg-4 p-0 px-1 m-0">
                            <ProfileInfo
                                profileData={profile}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        </div>
                        <div className="col-md-12 col-lg-8 p-0 justify-content-center">
                            {activeTab === 'collection' ? (
                                <>
                                    {profileData.isCreator ? (
                                        <>
                                            <Offers
                                                username={profile.username}
                                            />
                                        </>
                                    ) : null}
                                </>
                            ) : activeTab === 'owned' ? (
                                <>
                                    <div className="col-12 pt-5 pt-lg-0">
                                        <Collect
                                            username={profile.username}
                                            isMineProfile={isMineProfile}
                                        />
                                    </div>
                                </>
                            ) : activeTab === 'activity' ? (
                                <div className="col-12 pt-5 pt-lg-0">
                                    <Activity activity={[]} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
