import { minutesToMilliseconds } from 'date-fns';
import useSWR from 'swr';
import { FE_GET_USER_PROFILE_OFFERS } from '../utils/api-urls-fe';

function useProfileOffers(username, limit = 10) {
    const { data, error } = useSWR(
        !username ? null : FE_GET_USER_PROFILE_OFFERS(username, limit),
        {
            refreshInterval: minutesToMilliseconds(1),
        }
    );

    return {
        offers: data?.data,
        isLoading: !error && !data,
        isError: error,
    };
}

export { useProfileOffers };
