import React, { useCallback, useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { nanoid } from 'nanoid';
import fetcher from '../utils/fetch';

interface IConfig {
    maxFileSize: number;
    maxWidthOrHeight: number;
    compressImage: boolean;
}

const useImageUpload = (config: IConfig) => {
    const [uploading, setUploading] = useState(false);
    const [originalFile, setOriginalFile] = useState<File | undefined>(null);
    const [errors, setErrors] = useState([]);
    const [preview, setPreview] = useState(null);
    const [compressedFile, setCompressedFile] = useState(null);
    const [resultUrl, setResultUrl] = useState(null);
    const [success, setSuccess] = useState(undefined);

    const useImageCompression = async () => {
        // ----- compress the file on client side ---- //
        try {
            const options = {
                maxSizeMB: config?.maxFileSize ?? 10,
                maxWidthOrHeight: config?.maxWidthOrHeight ?? 250,
                useWebWorker: true,
                onProgress: (p) => onProgress(p),
            };

            const compressedFile = await imageCompression(
                originalFile,
                options
            );

            // console.log('originalFile ', originalFile);
            // console.log('compressedFile ', compressedFile);

            if (compressedFile) setCompressedFile(compressedFile);

            return compressedFile;
        } catch (error) {
            throw error;
        }
        // ------------------------------------------ //
    };

    const uploadFile = async () => {
        try {
            const filename = `${nanoid()}.${
                originalFile.name?.split('.')[1] ?? 'png'
            }`;

            const fileToUpload = config?.compressImage
                ? await useImageCompression()
                : originalFile;

            const file = new File([fileToUpload], fileToUpload.name, {
                type: originalFile.type,
                lastModified: originalFile.lastModified,
            });

            const apiRes = await fetcher(`api/uploads/aws?file=${filename}`);
            if (!apiRes.status) {
                setUploading(false);
                setErrors(['Picture upload failed. Please retry']);
            }

            const { url, fields } = apiRes.data;

            const fileData = new FormData();
            // formdata for file must be named file, otherwise upload fails //
            Object.entries({ ...fields, file }).forEach(
                ([key, value]: [any, any]) => {
                    fileData.append(key, value);
                }
            );

            const upload = await fetch(url, {
                method: 'POST',
                body: fileData,
            });

            if (upload.ok) {
                console.log('resultUrl ', url.concat('/' + filename));
                setResultUrl(url.concat('/' + filename));
                setSuccess(true);
                return true;
            }
        } catch (error) {
            console.error('Uploading failed.', error);
        }

        setErrors(['Uploading failed']);
        return false;
    };

    const onProgress = (p) => {};

    const onChangeFile = (file: File) => {
        console.log('useImageUpload - OnChangeFile');
        setErrors([]);

        if (uploading) {
            console.log('useImageUpload - uploading in progress');
            setErrors(['File uploading already in progress']);
            return;
        }

        if (!file || typeof file === typeof File) {
            console.log('useImageUpload - file not found');
            setErrors(['File not found']);
            return;
        }

        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type))
            setErrors([...errors, 'File format must be png, jpeg or jpg']);

        if (file.size / 1024 / 1024 > 10)
            setErrors([...errors, 'Picture size should be less than 10mb']);

        if (errors.length) return;

        setPreview(null);
        setResultUrl(null);
        setSuccess(undefined);
        setPreview(URL.createObjectURL(file));
        setOriginalFile(file);
    };

    useEffect(() => {
        if (originalFile && !uploading) {
            console.log('useImageUpload - useEffect');
            setUploading(true);
            uploadFile()
                .then(() => {
                    console.log('Success', true);
                    setSuccess(true);
                })
                .catch(() => {
                    console.log('Success', false);
                    setSuccess(false);
                })
                .finally(() => {
                    console.log('Uploading', false);

                    setPreview(undefined);
                    setOriginalFile(undefined);
                    setUploading(false);
                });
        }
        return () => {
            // setUploading(false);
            // setOriginalFile(undefined);
        };
    }, [originalFile]);

    return { uploading, preview, resultUrl, success, onChangeFile };
};

export default useImageUpload;
