import { useState, useEffect } from 'react';
import Sticky from 'react-sticky-el';
import stickyStyles from './sticjyCelebrityInfo.module.css';
import { StickyProfileNameAndImg } from './stickyNameAndImage';

export const StickyProfile = ({ profileData }) => {
    const [isVisible, setIsVisible] = useState(false);

    const { name, displayImage } = profileData;

    useEffect(() => {
        function showStickyProfileName(e: any) {
            let scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 600) {
                setIsVisible(true);
            }
            if (scrolled <= 600) {
                setIsVisible(false);
            }
        }
        document.addEventListener('scroll', showStickyProfileName);
        return () => {
            document.removeEventListener('scroll', showStickyProfileName);
        };
    }, []);

    return (
        <div>
            {isVisible && (
                <Sticky stickyStyle={{ zIndex: 101 }}>
                    <div className="row d-block d-sm-none">
                        <div
                            className={`col-12 px-4 py-2 ${stickyStyles.stickyRow}`}
                        >
                            <StickyProfileNameAndImg
                                name={name}
                                displayImage={displayImage}
                            />
                        </div>
                    </div>
                </Sticky>
            )}
        </div>
    );
};
