import stickyStyles from '../sticjyCelebrityInfo.module.css';

export const StickyProfileNameAndImg = ({ name, displayImage }) => {
    return (
        <div>
            <div
                className={`d-flex align-items-center px-3 py-1 bg-white ${stickyStyles.stickyContent}`}
            >
                <div className={`${stickyStyles.imageDiv}`}>
                    <img className="object-fit" src={displayImage} />
                </div>
                <div className="mx-2 color-black">{name}</div>
            </div>
        </div>
    );
};
